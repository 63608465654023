.report {
  font-family: "Neue Haas Grotesk Display Pro", sans-serif;
}

.report-body {
  padding-left: 100px;
  padding-right: 100px;
}

.report-header {
  background-color: #3a34b1;
  color: white;
  padding: 40px 50px 40px 100px;
  height: 153px;
}

.report-header-text {
  font-style: normal;
  font-weight: 400;
  font-size: 23px;
  line-height: 110%;
  letter-spacing: 0.04em;
  color: #ffffff;

  #report-header-subtitle {
    margin-top: 9px;
    font-weight: 500;
    font-size: 35px;
  }
}

.data-bar {
  height: 112px;
  background: #f1f0ff;
  padding: 15px 100px;
}

.data-bar-block-title {
  box-sizing: border-box;
  display: block;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 110%;
  letter-spacing: 0.04em;
  color: #6c6c6c;
}

.data-bar-block-subtitle {
  box-sizing: border-box;
  display: block;
  font-weight: 500;
  margin-top: 8px;
  font-size: 18px;
  text-transform: uppercase;
  color: #252525;
}

.data-bar-page-number {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 100px 10px 20px;
  gap: 10px;

  width: 128px;
  height: 42px;

  background: #3a34b1;

  color: #ffffff;
  font-weight: 500;
  font-size: 20px;
  line-height: 110%;
  text-align: center;
  letter-spacing: 0.04em;
  flex: none;
  flex-grow: 0;
  position: absolute;
  right: 0;
}

.result-breakdown-text {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 120%;
  /* or 19px */
  letter-spacing: 0.04em;

  color: #252525;

  /* Inside auto layout */
  flex: none;
  align-self: stretch;
  flex-grow: 0;
}


#result-breakdown-row-1 {
  gap: 15px;
}

#result-breakdown-row-2 {
  margin-top: 15px;
  gap: 15px;
}

.risk-line-container {
  width: 100%;
  height: 2px;
  background-color: #b9b9b9;
  border-radius: 5px;
}

.risk-line-perc-value {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #3a34b1;
  margin-left: auto;
  transform: translateY(-20px);
  height: 0;
}

.risk-line-perc-risk-box {
  transform: translate(-40%, -70px);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 15px;
  font-weight: 500;
  font-size: 16px;
  line-height: 110%;
  text-transform: uppercase;
  color: #ffffff;
  width: 108px;
  height: 38px;
  background: #3a34b1;
}

.risk-line-perc {
  height: 100%;
  background-color: #3a34b1;
  border-radius: 5px;
}

.risk-line-dot {
  width: 15px;
  height: 15px;
  background-color: #3a34b1;
  border-radius: 100%;
  transform: translateY(-50%);
  margin-left: auto;
  margin-right: 0;
}

.risk-line-perc-arrow-head {
  width: 11.77px;
  height: 11.77px;
  background: #3a34b1;
  transform: translate(0px, -78px) rotate(45deg);
}

.risk-line-labels {
  width: 100%;
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}

.treatment-table {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  border-collapse: collapse;
  border-style: hidden;

  td,
  th {
    border: 1px solid #b9b9b9;
  }

  tr {
    text-align: center;
    padding: 15px 25px;
    gap: 10px;
    width: 175.33px;
    height: 48px;
  }

  th {
    background: #f4f4f4;
  }

  td {
    font-weight: 400;
  }
}


.progress-bar-container {
  display: flex;
  width: 100%;
  height: 32px;
  margin-bottom: 17px;
  border: 2px solid #3a34b1;
}

.data-bar-bottom1 {
  margin-top: 74 + 120px;
}

.data-bar-bottom2 {
  margin-top: 433 + 97px;
}

.progress-bar {
  height: 100%;
  background: #3a34b1;
  color: white;
  padding: 6px 10px 6px 0px;
  text-align: end;
}

.progress-bar-text {
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
}

.treatment-simulations {
  margin-top: 26px;
}

.ataraxis-score {
  height: auto;
  margin-top: 130px;
  margin-bottom: 50px;
}

.at-score-risk-bar-container {
  display: flex;
  width: 100%;
  margin-top: 21px;
  height: 10px;
}

.at-score-risk-bar-dark-blue {
  display: flex;
  width: 40%;
  height: 10px;
  background: #3a34b1;
  color: white;
}

.at-score-risk-bar-orange {
  display: flex;
  width: 60%;
  height: 10px;
  background: #f4a444;
  color: white;
}

.at-risk-line-label {
  transform: translateY(-30px);
}

.at-score-risk-bar-labels-text {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  text-align: center;
  color: #6c6c6c;
  margin-top: 20px;
}

.report-form {
  margin-top: 50px;
  margin-bottom: 50px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-weight: 500;
  font-size: 40px;
}

.export-button {
  box-shadow: 10px 10px 5px #aaaaaa;
  position: fixed;
  bottom: 50px;
  right: 50px;
  cursor: pointer;
  padding: 10px 30px 10px 30px;
  font-weight: 500;
  font-size: 50px;
  z-index: 99;
}

.report-form-header {
  font-style: normal;
  font-weight: 600;
  font-size: 65px;
  line-height: 110%;
  color: #3a34b1;
  margin-bottom: 10px;
}

.report-form-input {
  width: 100%;
  border: 1px solid #b9b9b9;
  border-radius: 5px;
  padding: 10px;
  font-weight: 400;
  line-height: 110%;
  color: #000000;
  margin-bottom: 10px;
}

.report-form-button {
  color: #ffffff;
  background-color: #3a34b1;
  border-radius: 15px;
}

.order-form-section {
  background-color: rgba(58, 52, 177, 0.3);
  padding: 25px;
  font-size: 25px;
  border-radius: 20px;
}

.report-form-section-header {
  font-weight: 600;
  font-size: 30px;
  color: #3a34b1;
  margin-bottom: 10px;
}

.order-form-section-block {
  gap: 25px;
}

.cancer-subtypes-form-subsection {
  background-color: rgba(58, 52, 177, 0.2);
  padding: 15px 20px 25px 20px;
  margin-bottom: 25px;
  border-radius: 15px;
}

.add-more-foci-button {
  font-weight: 500;
  font-size: 25px;
  background-color: #3a34b1;
  border-radius: 10px;
  color: #ffffff;
  text-align: center;
  width: 40%;
}

.order-form-section-row {
  gap: 25px;
}

.preview-image {
  display: block;
  width: 50px;
  height: 100%;
}
