html,
body,
#root {
  height: 100%;
}

@font-face {
  font-family: neue haas grotesk display pro;
  font-style: normal;
  font-weight: 400;
  src: local("Neue Haas Grotesk Display Pro"), url(fonts/NeueHaasDisplayLight.ttf) format("truetype");
}

@font-face {
  font-family: neue haas grotesk display pro;
  font-style: italic;
  font-weight: 400;
  src: local("Neue Haas Grotesk Display Pro"), url(fonts/NeueHaasDisplayLightItalic.ttf) format("truetype");
}

@font-face {
  font-family: neue haas grotesk display pro;
  font-style: normal;
  font-weight: 100;
  src: local("Neue Haas Grotesk Display Pro"), url(fonts/NeueHaasDisplayXXThin.ttf) format("truetype");
}

@font-face {
  font-family: neue haas grotesk display pro;
  font-style: italic;
  font-weight: 100;
  src: local("Neue Haas Grotesk Display Pro"), url(fonts/NeueHaasDisplayXXThinItalic.ttf) format("truetype");
}

@font-face {
  font-family: neue haas grotesk display pro;
  font-style: normal;
  font-weight: 200;
  src: local("Neue Haas Grotesk Display Pro"), url(fonts/NeueHaasDisplayXThin.ttf) format("truetype");
}

@font-face {
  font-family: neue haas grotesk display pro;
  font-style: italic;
  font-weight: 200;
  src: local("Neue Haas Grotesk Display Pro"), url(fonts/NeueHaasDisplayXThinItalic.ttf) format("truetype");
}

@font-face {
  font-family: neue haas grotesk display pro;
  font-style: normal;
  font-weight: 300;
  src: local("Neue Haas Grotesk Display Pro"), url(fonts/NeueHaasDisplayThin.ttf) format("truetype");
}

@font-face {
  font-family: neue haas grotesk display pro;
  font-style: italic;
  font-weight: 300;
  src: local("Neue Haas Grotesk Display Pro"), url(fonts/NeueHaasDisplayThinItalic.ttf) format("truetype");
}

@font-face {
  font-family: neue haas grotesk display pro;
  font-style: normal;
  font-weight: 500;
  src: local("Neue Haas Grotesk Display Pro"), url(fonts/NeueHaasDisplayRoman.ttf) format("truetype");
}

@font-face {
  font-family: neue haas grotesk display pro;
  font-style: italic;
  font-weight: 500;
  src: local("Neue Haas Grotesk Display Pro"), url(fonts/NeueHaasDisplayRomanItalic.ttf) format("truetype");
}

@font-face {
  font-family: neue haas grotesk display pro;
  font-style: normal;
  font-weight: 600;
  src: local("Neue Haas Grotesk Display Pro"), url(fonts/NeueHaasDisplayMediu.ttf) format("truetype");
}

@font-face {
  font-family: neue haas grotesk display pro;
  font-style: italic;
  font-weight: 600;
  src: local("Neue Haas Grotesk Display Pro"), url(fonts/NeueHaasDisplayMediumItalic.ttf) format("truetype");
}

@font-face {
  font-family: neue haas grotesk display pro;
  font-style: normal;
  font-weight: 700;
  src: local("Neue Haas Grotesk Display Pro"), url(fonts/NeueHaasDisplayBold.ttf) format("truetype");
}

@font-face {
  font-family: neue haas grotesk display pro;
  font-style: italic;
  font-weight: 700;
  src: local("Neue Haas Grotesk Display Pro"), url(fonts/NeueHaasDisplayBoldItalic.ttf) format("truetype");
}

@font-face {
  font-family: neue haas grotesk display pro;
  font-style: normal;
  font-weight: 900;
  src: local("Neue Haas Grotesk Display Pro"), url(fonts/NeueHaasDisplayBlack.ttf) format("truetype");
}

@font-face {
  font-family: neue haas grotesk display pro;
  font-style: italic;
  font-weight: 900;
  src: local("Neue Haas Grotesk Display Pro"), url(fonts/NeueHaasDisplayBlackItalic.ttf) format("truetype");
}
